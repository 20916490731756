<template>
  <ion-page>
    <!-- <div class="background-main-view main-view"> -->
    <ion-header class="ion-no-border">
      <main-view-header />
    </ion-header>
    <ion-content
      v-if="selectedView === '1'"
      class="ion-padding-start ion-padding-end ion-padding-bottom"
      :scroll-events="true"
    >
      <ion-row
        class="text-left ion-no-padding"
      >
        <ion-col
          size-xs="12"
          size-xl="8"
        >
          <span class="text-64 text--white">{{ $store.state.userName }} <span class="text--primary">Properties</span></span>
        </ion-col>
        <ion-col
          size-xs="12"
          size-xl="4"
          class="ion-no-padding"
        >
          <ion-searchbar
            v-model="search"
            class="search-bar"
          />
        </ion-col>
      </ion-row>
      <ion-list>
        <ion-item
          v-for="{id, buildingName, buildingAddress, buildingPostCode, buildingCity} in landlordBuildings"
          :key="id"
          style="padding-top: 0!important;"
          button
          detail="false"
          lines="none"
          class="building rounded-corners ion-no-padding"
          @click="$router.push(`/landlord-properties/${id}`);"
        >
          <ion-grid class="grid">
            <ion-row>
              <ion-col class="border-bottom top-half">
                <!-- <i class="mdi mdi-office-building text--primary" /> -->
                <ion-icon
                  :icon="buildingIco"
                  style="font-size: 30px; margin-top: 3px;"
                  class="text--primary"
                />
                <div class="building-name">
                  <span class="text-16 text--white text--uppercase">{{ buildingName }}</span><br>
                  <span class="text-14 text--gray">{{ buildingAddress }}, </span>
                  <span class="text-14 text--gray">{{ buildingPostCode }}</span>
                  <span
                    style="padding-left: 5px;"
                    class="text-14 text--gray"
                  >{{ buildingCity }}</span>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="bottom-half">
                <div
                  class="ml-15 text--gray"
                >
                  <tippy>
                    <span class="text--bold text-10 text--uppercase">Devices</span><br>
                    <i
                      class="mdi mdi-view-dashboard text--primary"
                    />
                    <span class="text--white text-14 pl-5">{{ buildingDevices(id) }}</span>
                    <template
                      #content
                    >
                      <div
                        style="font-size: 14px; padding: 5px 10px 5px 10px;"
                      >
                        <span class="text--bold pb-5"><i class="mdi mdi-view-dashboard pr-5" />Devices in the property</span>
                        <div class="mt-5">
                          There {{ buildingDevices(id) > 1 ? 'are' : 'is' }} {{ buildingDevices(id) }} devices in this property
                        </div>
                      </div>
                    </template>
                  </tippy>
                </div>
                <div
                  class="ml-15 text--gray"
                >
                  <tippy>
                    <span
                      class="text--bold text-10 text--uppercase"
                    >Errors</span><br>
                    <i
                      class="mdi mdi-alert-circle"
                      :class="errors(id).length === 0 ? 'text--green' : 'text--danger'"
                    />
                    <span class="text--white text-14 pl-5">{{ errors(id).length }}</span>
                    <template
                      #content
                    >
                      <div
                        style="font-size: 14px; padding: 5px 10px 5px 10px;"
                      >
                        <span class="text--bold pb-5"> <i class="mdi mdi-alert-circle pr-5" />Errors</span>
                        <div class="mt-5">
                          <div class="mb-5">
                            There {{ errors(id).length > 1 ? 'are' : 'is' }} {{ errors(id).length }} errors in this property
                          </div>
                          <div
                            v-for="item in errors(id)"
                            :key="item"
                            style="padding-top: 0; padding-bottom: 0;"
                          >
                            <li v-if="item.type === 'gatewayDisconnected'">
                              Gateway disconnected in <span class="text--danger">{{ item.id }}</span>
                            </li>
                            <li v-else>
                              {{ defaultDeviceNames(item.deviceType) }} is disconnected in <span class="text--danger">{{ apartmentMapper(item.apartmentId) }}</span>
                            </li>
                          </div>
                        </div>
                      </div>
                    </template>
                  </tippy>
                </div>
                <div
                  class="ml-15 text--gray"
                >
                  <tippy>
                    <span class="text--bold text-10 text--uppercase">Alerts</span><br>
                    <i
                      class="mdi mdi-alert"
                      :class="alerts(id) === 0 ? 'text--green' : 'text--danger'"
                    />
                    <span class="text--white text-14 pl-5">{{ alerts(id) }}</span>
                    <template
                      #content
                    >
                      <div
                        style="font-size: 14px; padding: 5px 10px 5px 10px;"
                      >
                        <span class="text--bold pb-5"><i class="mdi mdi-alert pr-5" />Alerts</span>
                        <div class="mt-5">
                          There {{ alerts(id) > 1 ? 'are' : 'is' }} {{ alerts(id) }} alerts in this property
                        </div>
                      </div>
                    </template>
                  </tippy>
                </div>
                <div
                  class="ml-15 text--gray"
                >
                  <tippy>
                    <span
                      class="text--bold text-10 text--uppercase"
                    >Warnings</span><br>
                    <i
                      class="mdi mdi-comment-alert"
                      :class="warnings(id).length === 0 ? 'text--green' : 'text--danger'"
                    /><span class="text--white text-14 pl-5">{{ warnings(id).length }}</span>
                    <template
                      #content
                    >
                      <div
                        style="font-size: 14px; padding: 5px 10px 5px 10px;"
                      >
                        <span class="text--bold pb-5"><i class="mdi mdi-comment-alert pr-5" />Warnings</span>
                        <div class="mt-5">
                          There {{ warnings(id).length > 1 ? 'are' : 'is' }} {{ warnings(id).length }} warnings in this property
                        </div>
                      </div>
                    </template>
                  </tippy>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
          <i class="mdi mdi-chevron-right text--white text-22" />
        </ion-item>
        <ion-item
          lines="none"
          color="transparent"
          style="--min-height: 100px;"
        />
      </ion-list>
      <ion-fab
        slot="fixed"
        vertical="bottom"
        horizontal="end"
        @click="$router.push('/add-property')"
      >
        <ion-fab-button>
          <ion-icon
            color="dark"
            :icon="addOutline"
          />
        </ion-fab-button>
      </ion-fab>
    </ion-content>
    <!-- <ion-content
        v-else-if="selectedView === '2'"
        key="2"
      >
        page 2 <br>
        {{ selectedBuilding }}
      </ion-content> -->
    <!-- </div> -->
  </ion-page>
</template>

<script>
import { addOutline, businessOutline } from 'ionicons/icons'

import buildingIco from '../assets/images/building-ico.svg'
import mainViewHeader from '../components/Headers/MainViewHeaderComponent.vue'

import { defaultDeviceNames } from '../lib/variables'

export default {
  components: {
    mainViewHeader
  },
  data () {
    return {
      addOutline,
      businessOutline,
      buildingIco,
      loading: false,
      selectedView: '1',
      selectedBuilding: {},
      search: '',
      message: '',
      defaultDeviceNames
    }
  },
  computed: {
    landlordBuildings () {
      const buildings = this.$store.state.landlordBuildings

      return buildings.filter(item => {
        return item.buildingName.toLowerCase().includes(this.search.toLowerCase()) ||
               item.buildingAddress.toLowerCase().includes(this.search.toLowerCase()) ||
               item.buildingCity.toLowerCase().includes(this.search.toLowerCase()) ||
               item.buildingTenants.some(item => item.includes(this.search.toLowerCase()))
      })
    },
    landlordProperties () {
      return this.$store.state.landlordProperties
    },
    landlordDevices () {
      return this.$store.state.landlordDevices
    }
  },
  watch: {
    $route: function () {
      console.log(this.$route.path)
      if (this.$route.path === '/landlord-properties') {
        this.search = ''
      }
    }
  },
  created () {
    // this.getLandlordBuildingsAndProperties()
  },
  methods: {
    buildingDevices (id) {
      const devices = this.landlordDevices
      const devicesInBuilding = devices.filter(item => item.buildingId === id)
      return devicesInBuilding.length
    },
    errors (id) {
      const properties = this.landlordProperties
      const devices = this.landlordDevices
      const result = []
      properties.forEach(item => {
        if (item.gatewayActive === false && item.buildingId === id) {
          result.push(
            {
              type: 'gatewayDisconnected',
              id: item.propertyName
            }
          )
        }
      })
      devices.forEach(item => {
        if (item.active === false && item.buildingId === id) {
          result.push({
            type: 'deviceDisconnected',
            deviceType: item.typeI,
            apartmentId: item.propertyId
          })
        }
      })
      return result
    },
    alerts (id) {
      const properties = this.landlordProperties
      let count = 0
      properties.forEach(item => {
        if (item.fire === true && item.buildingId === id) {
          count++
        } else if (item.waterLeak === true && item.buildingId === id) {
          count++
        }
      })
      return count
    },
    warnings (id) {
      const devices = this.landlordDevices
      const lowBattery = devices.filter(item => item.battery === 1 && item.buildingId === id)
      return lowBattery
    },
    apartmentMapper (id) {
      const property = this.landlordProperties.filter(item => item.id === id)
      return property.length ? property[0].propertyName : 'N/A'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
ion-fab {
  position: fixed;
  bottom: 50px;
  right: 20px;
}
.container {
  min-height: 100%;
  padding-left: 2vw;
  padding-right: 2vw;
}
.text-64 {
    font-size: 36px;
}
.search-bar {
  --box-shadow: none;
  color: white;
  --background: rgba(0, 0, 0, 0.2);
  // background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.building {
  margin-top: 8px;
   @include bg-box-item;
   --min-height: 100px;
   ion-grid {
     height: 100%;
     padding-left: 20px;
     padding-right: 20px;
   }
   .top-half {
     padding: 0 0 5px 0;
     display: inline-flex;
     border-bottom: 1px solid rgba(255, 255, 255, 0.06);
     ion-list {
       padding-bottom: 50px;
     }
     .mdi-office-building {
       font-size: 35px;
     }
     .building-name {
       padding-top: 3px;
       padding-left: 20px;
     }
     .ico {
       font-size: 20px;
     }
     .floors-flats {
       padding: 10px;
     }
   }
   .bottom-half {
     padding: 5px 0 0 0;
     display: inline-flex;
   }
}
</style>